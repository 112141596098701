*,
*::before,
*::after {
  box-sizing: border-box;
}

header,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

html {
  font-size: 41.5%;
}

body {
  font-family: Arial, "Helvetica", "sans-serif";
  font-size: 1.8rem;
}

h1,
h2,
h3,
h4 {
  padding: 0;
  margin: 0;
}
